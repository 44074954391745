export const CC_API_ENDPOINTS = {
  savingsInfo: '/api/commandCenter/savings-info',
  runsSummary: '/api/commandCenter/runs-summary',
  runsInfo: '/api/commandCenter/runs-info',
  sprayerInfo: '/api/commandCenter/sprayer-info',
  subLocationInfo: '/api/commandCenter/sub-location-info',
  subLocationSprayedInfo: '/api/commandCenter/sub-location-sprayed-info',
  perfomanceInfo: '/api/commandCenter/performance-info'
};

export const LOCATIONS_API_ENDPOINTS = {
  blocks: '/api/locations/sub'
};

export const REPORTS_API_ENDPOINTS = {
  reports: '/api/reports',
  savingsReport: '/api/Reports/savings-report'
};
