import useSWR from 'swr';
import { IProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchSavings } from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';

const useSavings = ({ date, block = '' }: IProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const savingsParams = {
    subLocationId: block,
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, error, isLoading } = useSWR(
    `${CC_API_ENDPOINTS.savingsInfo}?${createQueryString(savingsParams)}`,
    () => fetchSavings(savingsParams)
  );

  return {
    data,
    isLoading,
    error
  };
};

export default useSavings;
