import useSWR from 'swr';
import { IProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchPerfomanceInfo } from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';

const usePerfomanceBreakdown = ({ date }: IProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const perfomanceInfoParams = {
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, isLoading, error } = useSWR(
    `${CC_API_ENDPOINTS.perfomanceInfo}?${createQueryString(perfomanceInfoParams)}`,
    () => fetchPerfomanceInfo(perfomanceInfoParams)
  );

  const { performanceInfo } = data || {};

  return {
    data: performanceInfo,
    isLoading,
    error
  };
};

export default usePerfomanceBreakdown;
