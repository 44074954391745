import React from 'react';
import styles from '../FilterPanel.module.scss';
import DateFilterOption from '../DateFilterOption';
import { IProperties } from './interface';

/**
 * RunsFilter component needs to filter and export run summary data.
 *
 * @example
 * <RunsFilter
 *    open={isOpen}
 *    properties={properties}
 *    setProperties={setProperties}
 *  />
 */

interface IFilterProps {
  open: boolean;
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const RunSummaryFilter: React.FC<IFilterProps> = ({
  open,
  properties,
  setProperties
}) => {
  return (
    <div className={open ? styles.filter : styles.filterClosed}>
      <DateFilterOption properties={properties} setProperties={setProperties} />
    </div>
  );
};

export default RunSummaryFilter;
