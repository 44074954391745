import React from 'react';
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './FilterPanel.module.scss';
import Text from '../typographies/Text/Text';
import { IProperties } from './interface';

/**
 * FilterOption component needs to display different options for filtering.
 *
 * @example
 * <FilterOption
 *   iconPath="icon path"
 *   title="Title"
 *   propertyName='name'
 *   options={[]}
 *   isLoading={isLoading}
 *   properties={properties}
 *   setProperties={setProperties}
 * />
 */

const MenuProps = {
  PaperProps: {
    className: styles.selectMenu
  }
};

interface IFilterOptionProps {
  iconPath: string;
  title: string;
  options: number[] | string[];
  isLoading: boolean;
  propertyName: string;
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const FilterOption: React.FC<IFilterOptionProps> = ({
  iconPath,
  title,
  options,
  isLoading,
  propertyName,
  properties,
  setProperties
}) => {
  const selectProperty = (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event;

    setProperties((prev: IProperties) => {
      return {
        ...prev,
        [propertyName]: value
      };
    });
  };

  const clearProperty = () => {
    setProperties((prev: IProperties) => {
      return {
        ...prev,
        [propertyName]: ''
      };
    });
  };

  return (
    <div className={styles.filterOption}>
      <img src={iconPath} />
      <div>
        <Text type="label" className={styles.title}>
          {title}
        </Text>
        <FormControl fullWidth className={styles.formControl}>
          <Select
            fullWidth
            displayEmpty
            value={properties?.[propertyName]?.toString()}
            onChange={selectProperty}
            input={
              <OutlinedInput
                classes={{ notchedOutline: styles.notchedOutline }}
              />
            }
            inputProps={{ className: styles.input }}
            className={styles.select}
            MenuProps={MenuProps}
          >
            <MenuItem value="" disabled>
              Select property...
            </MenuItem>
            {isLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              options.map((prop) => (
                <MenuItem key={prop} value={prop}>
                  {prop}
                </MenuItem>
              ))
            )}
          </Select>
          {properties?.[propertyName] && (
            <IconButton onClick={clearProperty}>
              <CloseIcon />
            </IconButton>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default FilterOption;
