import React from 'react';
import styles from './Heading.module.scss';

/**
 * Text component needs to show heading tag.
 *
 * @example
 * <Heading level={1} className='yourClassName' style={{ fontSize: '10px' }}>Your text</Text>
 */

interface IHeadingProps {
  level: 1 | 2 | 3;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Heading: React.FC<IHeadingProps> = ({
  level = 1,
  children,
  className = '',
  style = {}
}) => {
  const tagClassName = `h${level}`;
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <Tag className={`${className} ${styles[tagClassName]}`} style={style}>
      {children}
    </Tag>
  );
};

export default Heading;
