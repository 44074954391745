import useBreadcrumbs from 'use-react-router-breadcrumbs';
import AppRoutes from '../AppRoutes';
import { TAppRoute } from '../types/routes';

const useCustomBreadcrumbs = (excludePaths: string[] = []) => {
  const routes = (AppRoutes as TAppRoute[]).map((route) => ({
    path: route.path,
    breadcrumb: route?.breadcrumb || ''
  }));

  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: excludePaths
  });

  return breadcrumbs;
};

export default useCustomBreadcrumbs;
