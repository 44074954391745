import React, { useState } from 'react';
import BarChartWidget from '../widgets/BarChartWidget';

import styles from './AssetManagement.module.scss';
import { CHART_LEGEND_COLORS } from '../../constants/charts';
import RunsFilterPanel from '../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import { getDefaultDateFilterRange } from '../../utils/dateFormat';
import { IProperties } from '../FilterPanel/interface';
import useSprayerInfo from '../../hooks/useSprayerInfo';

const SprayerInfo: React.FC = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading } = useSprayerInfo(properties);

  const tractorName: string[] | undefined = data?.map((el) => el.tractorName);

  const totalRunsCount: number[] | undefined = data?.map(
    (el) => el.totalRunsCount
  );

  const tractorTotalPesticideSavedAmountGallons: number[] | undefined =
    data?.map((el) => el.tractorTotalPesticideSavedAmountGallons);

  const tractorTotalPesticideSprayedAmountGallons: number[] | undefined =
    data?.map((el) => el.tractorTotalPesticideSprayedAmountGallons);

  return (
    <BarChartWidget
      className={styles.widgetItem}
      title="📊 Savings per Sprayer"
      description="Shows savings per sprayer."
      subtitle="gal"
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      data={{
        labels: tractorName,
        datasets: [
          {
            label: 'Runs', // First bar for each day
            data: totalRunsCount || [],
            backgroundColor: CHART_LEGEND_COLORS.orange
          },
          {
            label: 'Herbicide sprayed', // Second bar for each day
            data: tractorTotalPesticideSprayedAmountGallons || [],
            backgroundColor: CHART_LEGEND_COLORS.blue
          },
          {
            label: 'Herbicide saved', // Third bar for each day
            data: tractorTotalPesticideSavedAmountGallons || [],
            backgroundColor: CHART_LEGEND_COLORS.green
          }
        ]
      }}
    />
  );
};

export default SprayerInfo;
