import React, { useRef, useState } from 'react';
import WidgetGroup from '../WidgetGroup';
import TotalNumberWidget from '../widgets/TotalNumberWidget';

import styles from './Savings.module.scss';
import SavingsFilterPanel from '../FilterPanel/SavingsFilterPanel/SavingsFilterPanel';
import LineChartWidget from '../widgets/LineChartWidget';
import { IProperties } from '../FilterPanel/SavingsFilterPanel/interface';
import useSavings from '../../hooks/useSavings';
import { FIXED_PRECISION } from '../../constants/numbers';
import { getDate, getDefaultDateFilterRange } from '../../utils/dateFormat';
import { exportToFileFromElements } from '../../utils/file-export-utils';

interface IPesticideUsage {
  date: string;
  pesticideUsedAmountGallons: number;
}

interface IPesticideSavings {
  date: string;
  pesticideSavedAmountGallons: number;
}

const WIDGETS = {
  SAVINGS: {
    id: 'SAVINGS',
    title: '📊 Herbicide Savings',
    description: 'Displays the amount of herbicide saved in gallons.'
  },
  SPRAYED: {
    id: 'SPRAYED',
    title: '📊 Sprayed area',
    description: 'Shows the total area sprayed in acres.'
  },
  USAGE: {
    id: 'USAGE',
    title: '📊 Herbicide Usage',
    subtitle: 'gal',
    description: 'Displays the herbicide usage dynamics.'
  },
  SAVINGS_DYNAMICS: {
    id: 'SAVINGS_DYNAMICS',
    title: '📊 Herbicide Savings Dynamics',
    subtitle: 'gal',
    description: 'Shows the herbicide savings trend.'
  }
};

const Savings: React.FC = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange(),
    block: ''
  });
  const [loadingExport, setLoadingExport] = useState(false);

  const { data, isLoading } = useSavings(properties);

  const printRefs = useRef<HTMLDivElement | null>(null);

  const pesticideUsagePesticideUsedAmountGallons: number[] | undefined =
    data?.pesticideUsage.map(
      (el: IPesticideUsage) => el.pesticideUsedAmountGallons
    );

  const pesticideSavingsPesticideUsedAmountGallons: number[] | undefined =
    data?.pesticideSavings?.map(
      (el: IPesticideSavings) => el.pesticideSavedAmountGallons
    );

  const handleExportFile = async () => {
    const elementIds = Object.values(WIDGETS).map((widget) => widget.id);
    await exportToFileFromElements('pdf', elementIds, setLoadingExport);
  };

  return (
    <div ref={printRefs}>
      <WidgetGroup title="💰 Savings">
        <SavingsFilterPanel
          properties={properties}
          setProperties={setProperties}
          handleExportFile={handleExportFile}
          loading={loadingExport}
        />
        <section className={styles.row}>
          <TotalNumberWidget
            id={WIDGETS.SAVINGS.id}
            isLoading={isLoading}
            title={WIDGETS.SAVINGS.title}
            description={WIDGETS.SAVINGS.description}
            data={{
              value: parseFloat(
                data?.totalPesticideSavedAmountGallons?.toFixed(
                  FIXED_PRECISION
                ) ?? ''
              ),
              label: 'Gallons'
            }}
          />
          <TotalNumberWidget
            id={WIDGETS.SPRAYED.id}
            isLoading={isLoading}
            title={WIDGETS.SPRAYED.title}
            description={WIDGETS.SPRAYED.description}
            data={{
              value: parseFloat(
                data?.totalSprayedAreaAcres?.toFixed(FIXED_PRECISION) ?? ''
              ),
              label: 'Acres'
            }}
          />
        </section>
        <div className={styles.row}>
          <LineChartWidget
            id={WIDGETS.USAGE.id}
            isLoading={isLoading}
            data={{
              labels: getDate(data?.pesticideUsage),
              datasets: [
                {
                  data: pesticideUsagePesticideUsedAmountGallons || []
                }
              ]
            }}
            title={WIDGETS.USAGE.title}
            subtitle={WIDGETS.USAGE.subtitle}
            description={WIDGETS.USAGE.description}
          />
          <LineChartWidget
            id={WIDGETS.SAVINGS_DYNAMICS.id}
            isLoading={isLoading}
            data={{
              labels: getDate(data?.pesticideSavings),
              datasets: [
                {
                  data: pesticideSavingsPesticideUsedAmountGallons || []
                }
              ]
            }}
            title={WIDGETS.SAVINGS_DYNAMICS.title}
            subtitle={WIDGETS.SAVINGS_DYNAMICS.subtitle}
            description={WIDGETS.SAVINGS_DYNAMICS.description}
          />
        </div>
      </WidgetGroup>
    </div>
  );
};

export default Savings;
