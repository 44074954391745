import React from 'react';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';
import TableComponent from '../../Table';
import { IProperties } from '../../FilterPanel/RunsFilterPanel/interface';

/**
 * TableWidget component needs to show user widhet with table data.
 *
 * @example
 *  <TableWidget title="Your title" description="Your descr">
 *    Some children data
 *  </TableWidget>
 */

interface Props extends Omit<IWidgetCardProps, 'children'> {
  columns: { id: string | number; label: string }[];
  rows: { [key: string]: string | number }[];
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
  isExport?: boolean;
}

const TableWidget: React.FC<Props> = ({
  title,
  description,
  isLoading,
  columns,
  rows,
  properties,
  setProperties,
  filter,
  isExport
}) => {
  return (
    <WidgetCard
      title={title}
      description={description}
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      filter={filter}
      isExport={isExport}
    >
      <TableComponent columns={columns} rows={rows} />
    </WidgetCard>
  );
};

export default TableWidget;
