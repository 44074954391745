import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Pagination from './TablePagination';
import styles from './Table.module.scss';
import clsx from 'clsx';

interface Column {
  id: string | number;
  label: string;
}

interface Data {
  [key: string]: string | number;
}

interface TableComponentProps {
  columns: Column[];
  rows: Data[];
  rowsPerPage?: number;
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  rows,
  rowsPerPage = 10
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const getPaginatedRows = (page: number) => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return rows.slice(start, end);
  };

  const paginatedRows = getPaginatedRows(currentPage);

  return (
    <div className={styles.tableWrapper}>
      <TableContainer className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={clsx(
                    { [styles.link]: column.id === 'link' },
                    styles.tableCell
                  )}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      className={clsx(
                        { [styles.link]: column.id === 'link' },
                        styles.tableCell
                      )}
                    >
                      {column.id === 'link' ? (
                        <IconButton
                          href={String(value)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.button}
                        >
                          <ArrowOutwardIcon />
                        </IconButton>
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default TableComponent;
