import { Plugin, RadialLinearScale } from 'chart.js';
import { CHART_FONT, CHART_LEGEND_COLORS } from '../../../constants/charts';

/**
 * Custom plugin for ticks with border radius and custom styling
 */
export const customTicksPlugin: Plugin<'radar'> = {
  id: 'customTicks',
  beforeDatasetDraw(chart) {
    const { ctx, scales } = chart;
    const scale = scales.r as RadialLinearScale; // Access the radial scale
    if (!scale) return;

    // Loop through each tick and apply custom rendering
    scale.ticks.forEach((tick) => {
      if (tick.value === 0) return; // Ignore the 0 tick

      // Calculate the position for the tick
      const y = scale.yCenter - scale.getDistanceFromCenterForValue(tick.value); // Top to center
      const x = scale.xCenter; // Align ticks vertically at the center

      // Custom backdrop styling for ticks
      ctx.save(); // Save the canvas state
      ctx.beginPath();
      ctx.fillStyle = CHART_LEGEND_COLORS.background; // Background color of the tick
      ctx.strokeStyle = CHART_LEGEND_COLORS.graySecondary; // Border color
      ctx.lineWidth = 1; // Border width

      const radius = 16; // Border radius
      const textWidth = ctx.measureText(tick.value.toString()).width + radius; // Adjust padding
      const textHeight = 22; // Height of the backdrop
      const rectX = x - textWidth / 2; // Center horizontally
      const rectY = y - textHeight / 2; // Center vertically

      // Draw rounded rectangle (backdrop)
      ctx.roundRect(rectX, rectY, textWidth, textHeight, radius);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();

      // Draw tick text
      ctx.fillStyle = CHART_LEGEND_COLORS.points; // Text color
      ctx.font = `12px ${CHART_FONT}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(tick.value.toString(), x, y);

      ctx.restore(); // Restore the canvas state
    });
  }
};
