import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

const UpdateTractorDialog = ({
  tractor,
  locations,
  open,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, watch } = useForm({ defaultValues: tractor });
  const autoShutOffAfterRun = watch('autoShutOffAfterRun', false);
  const defaultLocationId = watch(
    'defaultLocationId',
    locations && locations.length > 0 ? locations[0].id : ''
  );

  const updateTractor = async (data) => {
    if (
      !data.name ||
      !data.defaultLocationId ||
      !data.defaultApplicationRateGalAcre ||
      !data.totalBoomWidthFeet ||
      !data.maxSprayWidthFeet ||
      !data.heartbeatFrequencySec
    ) {
      return toast.error('Please complete all required fields.');
    }
    const token = await authService.getAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/update`,
        {
          method: 'PUT',
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
          body: JSON.stringify(data)
        }
      );
      if (response.ok) {
        toast.success('Tractor updated successfully!');
        onSuccess();
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (err) {
      toast.error('Error: ' + err);
      console.log('err', err);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(updateTractor)}>
        <DialogTitle>
          <EditIcon /> Edit Tractor
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormLabel>Sprayer Name</FormLabel>
              <TextField autoFocus required {...register('name')} />
            </FormControl>
            <FormControl>
              <FormLabel>Default Location</FormLabel>
              <Select
                value={defaultLocationId}
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                }
                {...register('defaultLocationId')}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>Default Application Rate (Gal/Acre)</FormLabel>
                <TextField
                  required
                  type="number"
                  {...register('defaultApplicationRateGalAcre')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Total Boom Width (Feet)</FormLabel>
                <TextField
                  required
                  type="number"
                  {...register('totalBoomWidthFeet')}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>Max Spray Width (Feet)</FormLabel>
                <TextField
                  required
                  type="number"
                  {...register('maxSprayWidthFeet')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Heartbeat Frequency (Secs)</FormLabel>
                <TextField
                  required
                  type="number"
                  {...register('heartbeatFrequencySec')}
                />
              </FormControl>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoShutOffAfterRun}
                  {...register('autoShutOffAfterRun')}
                />
              }
              label="Auto Shutdown After Run Completion"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="outlined" disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateTractorDialog;
