import React from 'react';
import { TextField } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import styles from './Table.module.scss';
import Text from '../typographies/Text/Text';

interface IPaginationProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: React.FC<IPaginationProps> = ({
  totalPages,
  currentPage,
  setCurrentPage
}) => {
  const handlePageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputPage = parseInt(e.target.value);

    if (isNaN(inputPage) || inputPage < 1) {
      inputPage = 1;
    } else if (inputPage > totalPages) {
      inputPage = totalPages;
    }

    setCurrentPage(inputPage);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className={styles.paginationContainer}>
      <div className={styles.pageCounter}>
        <Text tag="label">Page</Text>
        <TextField
          type="number"
          size="small"
          value={currentPage}
          onChange={handlePageChange}
          className={styles.textFieldCustom}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none' // Removes border from the TextField
              }
            }
          }}
        />
        <Text tag="label">of {totalPages}</Text>
      </div>
      <div className={styles.buttons}>
        <Text
          tag="button"
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        >
          <ArrowBack fontSize="small" /> Previous
        </Text>
        <Text
          tag="button"
          onClick={handleNextClick}
          disabled={currentPage === totalPages}
        >
          Next <ArrowForward fontSize="small" />
        </Text>
      </div>
    </div>
  );
};

export default Pagination;
