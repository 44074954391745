import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions
} from 'chart.js';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';
import { CHART_FONT, CHART_LEGEND_DIVISION } from '../../../constants/charts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CHART_LEGEND_SIZE = 10;

interface Props extends Omit<IWidgetCardProps, 'children'> {
  /**
   * Data for the bar chart.
   * Should follow the Chart.js data structure for bar charts.
   */
  data: ChartData<'bar'>;
  /**
   * Chart.js options for customizing the bar chart.
   */
  options?: ChartOptions<'bar'>;
  /**
   * Chart.js subtitle for display subtitle in the chart.
   */
  subtitle?: string;
}

/**
 * A reusable widget component that renders a bar chart using Chart.js and react-chartjs-2.
 */
const BarChartWidget: React.FC<Props> = ({
  data,
  options,
  subtitle,
  ...widgetProps
}) => {
  return (
    <WidgetCard {...widgetProps}>
      <Bar
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top' as const,
              labels: {
                font: {
                  family: CHART_FONT
                },
                boxWidth: CHART_LEGEND_SIZE,
                boxHeight: CHART_LEGEND_SIZE,
                usePointStyle: true,
                pointStyle: 'rectRounded'
              }
            },
            subtitle: {
              display: !!subtitle,
              position: 'left',
              align: 'center',
              text: subtitle
            }
          },
          scales: {
            x: {
              stacked: false,
              beginAtZero: true,
              ticks: {
                font: {
                  family: CHART_FONT
                }
              },
              grid: {
                drawOnChartArea: false, // Disables grid lines for the chart area
                drawTicks: true, // Shows grid lines only for ticks
                color: '#D3D3D3', // Custom color for X-axis grid lines
                lineWidth: 1 // Thickness of the grid lines
              }
            },
            y: {
              stacked: false,
              grid: {
                drawTicks: true, // Draw grid lines at ticks
                drawOnChartArea: true, // Draw grid lines across the chart area
                color: (context) => {
                  // Draw lines only for visible ticks
                  return context.tick.value % CHART_LEGEND_DIVISION === 0
                    ? '#E9E9E9'
                    : undefined;
                }
              },
              ticks: {
                font: {
                  family: CHART_FONT
                },
                callback: function (value) {
                  // Show only labels that are divisible by 5
                  return Number(value) % CHART_LEGEND_DIVISION === 0
                    ? value
                    : '';
                }
              }
            }
          },
          datasets: {
            bar: {
              barThickness: 'flex',
              maxBarThickness: 18,
              borderRadius: 4
            }
          },
          layout: {
            padding: { left: 6, right: 6 }
          },
          ...options
        }}
      />
    </WidgetCard>
  );
};

export default BarChartWidget;
