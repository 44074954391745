import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import Text from '../../typographies/Text/Text';
import RunsFilter from './RunsFilter';
import { IProperties } from './interface';

/**
 * RunsFilterPanel component needs to filter and export run summary data.
 *
 * @example
 * <RunsFilterPanel properties={properties} setProperties={setPproperties}/>
 */

interface IDateFilterOptionProps {
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const RunsFilterPanel: React.FC<IDateFilterOptionProps> = ({
  properties,
  setProperties
}) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const onFilterToggle = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setFilterOpen(false);
  };

  const appliedFilters = Object.entries(properties).filter(
    ([, value]) => value
  );
  const appliedFiltersCount = appliedFilters.length;

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <div>
        <Text tag="button" onClick={onFilterToggle}>
          <img src="/images/filter_alt.svg" />
          Filter
          <span>{appliedFiltersCount > 0 && `• ${appliedFiltersCount}`}</span>
        </Text>
        {filterOpen ? (
          <RunsFilter
            open={filterOpen}
            properties={properties}
            setProperties={setProperties}
          />
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default RunsFilterPanel;
