import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Stack,
  TextField
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

const UpdateLocationDialog = ({ location, open, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm({ defaultValues: location });

  const updateLocation = async (data) => {
    if (
      !data.name ||
      !data.streetAddress ||
      !data.city ||
      !data.state ||
      !data.zipCode ||
      !data.country
    )
      return toast.error('Please complete all required fields.');

    const token = await authService.getAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/locations/update`,
        {
          method: 'PUT',
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
          body: JSON.stringify(data)
        }
      );
      if (response.ok) {
        toast.success('Location updated successfully!');
        onSuccess();
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (err) {
      toast.error('Error: ' + err);
      console.log('err', err);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(updateLocation)}>
        <DialogTitle>
          <EditIcon /> Edit Location
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormLabel>Location Name</FormLabel>
              <TextField autoFocus required {...register('name')} />
            </FormControl>
            <FormControl>
              <FormLabel>Street Address</FormLabel>
              <TextField required {...register('streetAddress')} />
            </FormControl>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>City</FormLabel>
                <TextField required {...register('city')} />
              </FormControl>
              <FormControl>
                <FormLabel>State</FormLabel>
                <TextField required {...register('state')} />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <TextField required {...register('zipCode')} />
              </FormControl>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <TextField required {...register('country')} />
              </FormControl>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="outlined" disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateLocationDialog;
