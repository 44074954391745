import useSWR from 'swr';
import { IProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchRunsSummary } from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';

const useRunsSummary = ({ date }: IProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const runsSummaryParams = {
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, isLoading, error } = useSWR(
    `${CC_API_ENDPOINTS.runsSummary}?${createQueryString(runsSummaryParams)}`,
    () => fetchRunsSummary(runsSummaryParams)
  );
  const { runSummaries } = data || {};

  return {
    data: runSummaries,
    isLoading,
    error
  };
};

export default useRunsSummary;
