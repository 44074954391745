import React from 'react';
import WidgetGroup from '../WidgetGroup';
import styles from './HerbicideSavings.module.scss';
import SavingsPerBlock from './SavingsPerBlock';
import SavingsAllBlocks from './SavingsAllBlocks';

const HerbicideSavings = () => {
  return (
    <WidgetGroup title="🌱 Herbicide Savings">
      <div className={styles.row}>
        <SavingsPerBlock />
        <SavingsAllBlocks />
      </div>
    </WidgetGroup>
  );
};

export default HerbicideSavings;
