import React, { useState } from 'react';
import { getDefaultDateFilterRange, UTCFormat } from '../../utils/dateFormat';
import TableWidget from '../widgets/TableWidget';
import { IProperties } from '../FilterPanel/RunsFilterPanel/interface';
import { dateFormatMonthDayYear } from '../../constants/dateFormat';
import RunsFilterPanel from '../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import useRunsSummary from '../../hooks/useRunsSummary';

const RunsSummary = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading } = useRunsSummary(properties);

  const formattedRows = data?.map((row) => ({
    ...row,
    date: UTCFormat(new Date(row.date), dateFormatMonthDayYear)
  }));

  const columns = [
    { id: 'operatorName', label: 'Operator' },
    { id: 'date', label: 'Date' },
    { id: 'subLocationId', label: 'Block' },
    { id: 'tractorName', label: 'Vehicle' },
    { id: 'dayRunAmount', label: 'Covered acres' },
    { id: 'link', label: '' }
  ];

  // TODO: data?.length will change to total paginated values from BE.
  const totalRuns = `${data?.length || 0} run${data?.length === 1 ? '' : 's'}`;
  const summary = `Displays a summary of the last ${totalRuns}.`;

  return (
    <TableWidget
      title="📋 Run Summary"
      description={summary}
      isLoading={isLoading}
      columns={columns}
      rows={formattedRows || []}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
    />
  );
};

export default RunsSummary;
