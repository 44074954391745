import useSWR from 'swr';
import { IProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchSubLocationInfo } from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';

const useSavingsAllBlocks = ({ date }: IProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const subLocationInfoParams = {
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, isLoading, error } = useSWR(
    `${CC_API_ENDPOINTS.subLocationInfo}?${createQueryString(subLocationInfoParams)}`,
    () => fetchSubLocationInfo(subLocationInfoParams)
  );

  const { subLocationsInfo } = data || {};

  return {
    data: subLocationsInfo,
    isLoading,
    error
  };
};

export default useSavingsAllBlocks;
