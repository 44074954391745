import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import authService from '../../api-authorization/AuthorizeService';
import RequestOverlay from '../../components/RequestOverlay';
import styles from './AdminConfigLocations.module.css';
import AddLocationDialog from './AddLocationDialog/AddLocationDialog';
import UpdateLocationDialog from './UpdateLocationDialog/UpdateLocationDialog';

const AdminConfigLocations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filter, setFilter] = useState('');
  const [isAddLocationDialogOpen, setIsAddLocationDialogOpen] = useState(false);
  const [isUpdateLocationDialogOpen, setIsUpdateLocationDialogOpen] =
    useState(false);
  const [locationToUpdate, setLocationToUpdate] = useState(null);

  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);

  const fetchClients = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const clientData = await response.json();
      setClients(clientData);
      setLoading(false);
      setError(false);
      const client = clientData.find(
        (c) => c.id === parseInt(searchParams.get('clientId'), 10)
      );
      setSelectedClient(client ? client : clientData[0]);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      setSearchParams({ clientId: selectedClient.id });
    }
  }, [setSearchParams, selectedClient]);

  const handleClientSelectChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const fetchLocations = async () => {
    if (!selectedClient) {
      return;
    }
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/locations/all?ClientId=${selectedClient.id}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const locationData = await response.json();
      setLocations(locationData);
    } catch (error) {
      toast.error('Communication with server failed. Please reload your page.');
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [selectedClient]);

  const openAddLocationDialog = () => {
    setIsAddLocationDialogOpen(true);
  };

  const closeAddLocationDialog = () => {
    setIsAddLocationDialogOpen(false);
  };

  const handleAddLocationSuccess = () => {
    closeAddLocationDialog();
    fetchLocations();
  };

  const openUpdateLocationDialog = (location) => {
    setLocationToUpdate(location);
    setIsUpdateLocationDialogOpen(true);
  };

  const closeUpdateLocationDialog = () => {
    setIsUpdateLocationDialogOpen(false);
  };

  const handleUpdateLocationSuccess = () => {
    closeUpdateLocationDialog();
    fetchLocations();
  };

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={loading}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />
        <Typography variant="h4" sx={{ pb: 1 }}>
          Location Management
        </Typography>
        {clients && (
          <Stack direction="row" justifyContent="space-between">
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Select
                value={selectedClient}
                onChange={handleClientSelectChange}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                }
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                placeholder="Filter"
                variant="outlined"
                onChange={handleFilterChange}
                value={filter}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterAltIcon />
                      </InputAdornment>
                    )
                  }
                }}
              />
            </Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={openAddLocationDialog}
            >
              Add Location
            </Button>
          </Stack>
        )}
        <TableContainer component={Paper} sx={{ my: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Street Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Zip Code</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations
                .filter((location) =>
                  Object.values(location)
                    .join()
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                )
                .map((location) => (
                  <TableRow key={location.id}>
                    <TableCell>{location.id}</TableCell>
                    <TableCell>{location.name}</TableCell>
                    <TableCell>{location.streetAddress}</TableCell>
                    <TableCell>{location.city}</TableCell>
                    <TableCell>{location.state}</TableCell>
                    <TableCell>{location.zipCode}</TableCell>
                    <TableCell>{location.country}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => openUpdateLocationDialog(location)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* if-gates force to re-render with the location to be updated */}
        {isAddLocationDialogOpen && (
          <AddLocationDialog
            open={isAddLocationDialogOpen}
            client={selectedClient}
            onClose={closeAddLocationDialog}
            onSuccess={handleAddLocationSuccess}
          />
        )}
        {isUpdateLocationDialogOpen && (
          <UpdateLocationDialog
            open={isUpdateLocationDialogOpen}
            location={locationToUpdate}
            onClose={closeUpdateLocationDialog}
            onSuccess={handleUpdateLocationSuccess}
          />
        )}
      </div>
    </>
  );
};

export default AdminConfigLocations;
