import html2canvas from 'html2canvas';
import { fetchReports } from './api-utils';
import { downloadFile } from './file-utils';

// Utility function to handle common logic for both single and multiple elements
const exportToFile = async (
  type: 'pdf' | 'xlsx',
  elements: (HTMLElement | null)[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (elements.length > 0) {
    // Create a new <style> element to add custom CSS rules for correct screen view
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }'
    );

    try {
      setLoading(true);
      const images = await Promise.all(
        elements.map(async (element) => {
          if (element) {
            const canvas = await html2canvas(element);
            const imageUrl = canvas.toDataURL('image/png');
            const imageBlob = await (await fetch(imageUrl)).blob();
            return imageBlob;
          }
          return null;
        })
      );

      const formData = new FormData();
      images
        .filter((imageBlob): imageBlob is Blob => imageBlob !== null)
        .forEach((imageBlob, index) => {
          formData.append(
            'files',
            imageBlob,
            `exported_image_${index + 1}.png`
          );
        });

      const response = await fetchReports({ type }, formData);
      downloadFile(response);
    } catch (error) {
      console.error('Error exporting elements:', error);
    } finally {
      style.remove();
      setLoading(false);
    }
  }
};

// Export for single element
export const exportToFileFromElement = async (
  type: 'pdf' | 'xlsx',
  elementRef: React.RefObject<HTMLElement>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  await exportToFile(type, [elementRef.current], setLoading);
};

// Export for multiple elements
export const exportToFileFromElements = async (
  type: 'pdf' | 'xlsx',
  elementIds: string[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const refs = elementIds.map(
    (id) => document.querySelector(`#${id}`) as HTMLElement | null
  );
  await exportToFile(type, refs, setLoading);
};
