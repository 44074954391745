import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import authService from '../../api-authorization/AuthorizeService';
import RequestOverlay from '../../components/RequestOverlay';
import styles from './AdminConfigClients.module.css';
import AddClientDialog from './AddClientDialog/AddClientDialog';
import UpdateClientDialog from './UpdateClientDialog/UpdateClientDialog';

const AdminConfigClients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [isAddClientDialogOpen, setIsAddClientDialogOpen] = useState(false);
  const [isUpdateClientDialogOpen, setIsUpdateClientDialogOpen] =
    useState(false);
  const [clientToUpdate, setClientToUpdate] = useState(null);

  const fetchClients = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const clientData = await response.json();
      setClients(clientData);
      setLoading(false);
      setError(false);
    } catch (error) {
      console.log('error', error);
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const openAddClientDialog = () => {
    setIsAddClientDialogOpen(true);
  };

  const closeAddClientDialog = () => {
    setIsAddClientDialogOpen(false);
  };

  const handleAddClientSuccess = () => {
    closeAddClientDialog();
    fetchClients();
  };

  const openUpdateClientDialog = (client) => {
    setClientToUpdate(client);
    setIsUpdateClientDialogOpen(true);
  };

  const closeUpdateClientDialog = () => {
    setClientToUpdate(null);
    setIsUpdateClientDialogOpen(false);
  };

  const handleUpdateClientSuccess = () => {
    closeUpdateClientDialog();
    fetchClients();
  };

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={loading}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Client Management</Typography>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={openAddClientDialog}
          >
            Add Client
          </Button>
        </Stack>
        <div className="my-8">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client) => (
                  <TableRow key={client.id}>
                    <TableCell>{client.name}</TableCell>
                    <TableCell>{client.primaryContactEmail}</TableCell>
                    <TableCell>{client.primaryContactPhone}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => openUpdateClientDialog(client)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* if-gates force to re-render with the client to be updated */}
          {isAddClientDialogOpen && (
            <AddClientDialog
              open={isAddClientDialogOpen}
              onClose={closeAddClientDialog}
              onSuccess={handleAddClientSuccess}
            />
          )}
          {isUpdateClientDialogOpen && (
            <UpdateClientDialog
              open={isUpdateClientDialogOpen}
              client={clientToUpdate}
              onClose={closeUpdateClientDialog}
              onSuccess={handleUpdateClientSuccess}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminConfigClients;
