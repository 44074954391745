import React, { useState } from 'react';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

interface IDeleteNozzleDialogProps {
  clientId: string;
  nozzleId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteNozzleDialog: React.FC<IDeleteNozzleDialogProps> = ({
  clientId,
  nozzleId,
  open,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);

  const deleteNozzle = async () => {
    setLoading(true);
    const token = await authService.getAccessToken();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzle?clientId=${clientId}&nozzleId=${nozzleId}`,
      {
        method: 'DELETE',
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
      }
    );
    if (response.ok) {
      toast.success('Nozzle deleted successfully!');
      onSuccess();
    } else {
      toast.error(`${response.status} ${response.statusText}`);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <DeleteIcon /> Delete Nozzle
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete this nozzle?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" disabled={loading} onClick={deleteNozzle}>
          Delete Nozzle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNozzleDialog;
