import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import arrowicon from "../../../images/arrowicon.svg";
import styles from "./Breadcrumbs.module.css";
import useCustomBreadcrumbs from "../../../hooks/useBreadcrumbsHook";

const Separator = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={6}
    viewBox="0 0 6 6"
    fill="none"
    {...props}
  >
    <circle cx={3} cy={3} r={3} fill="#8dc34d" />
  </svg>
);

export default function Breadcrumbs({ items }) {
  const navigate = useNavigate();
  const location = useLocation();

  const excludePaths = ["/", "/home", "/command-center"];

  const breadcrumbs = useCustomBreadcrumbs(excludePaths);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  if (excludePaths.includes(location.pathname)) {
    return null;
  }

  return (
    <ol
      className={
        styles.container +
        " " +
        "flex items-center whitespace-nowrap min-w-0 my-2"
      }
      aria-label="Breadcrumb"
    >
      <li>
        <img
          onClick={handleNavigateBack}
          src={arrowicon}
          alt="arrow"
          className={`mr-3 cursor-pointer	${
            breadcrumbs.length < 1 ? "grayscale	pointer-events-none	" : ""
          } `}
          height={22}
          width={22}
        />
      </li>

      <li className="text-sm">
        <Link
          className={`flex items-center text-gray-500 hover:text-[#8dc34d] ${
            breadcrumbs.length === 0
              ? "text-sm font-semibold text-black font-bold truncate"
              : ""
          }`}
          to="/"
        >
          Home
          {breadcrumbs.length >= 1 && <Separator className="mx-2" />}
        </Link>
      </li>

      {breadcrumbs.map((item, index) => (
        <li key={item.key} className="text-sm">
          <Link
            className={`flex items-center text-gray-500 hover:text-[#8dc34d] ${
              index === breadcrumbs.length - 1 &&
              "text-sm font-semibold text-black font-bold truncate"
            }`}
            to={item.key}
          >
            {item.breadcrumb}
            {index < breadcrumbs.length - 1 && <Separator className="mx-2" />}
          </Link>
        </li>
      ))}
    </ol>
  );
}
