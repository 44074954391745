import React from 'react';

import Heading from '../typographies/Heading/Heading';
import styles from './WidgetGroup.module.scss';

interface Props {
  /**
   * Title of widget group.
   */
  title: string;
}

/**
 * Common component for widget groups like Saving, Runs, etc.
 * @example <WidgetGroup title="💰Savings">test</WidgetGroup>
 */
const WidgetGroup: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children
}) => {
  return (
    <section>
      <Heading className={styles.title} level={2}>
        {title}
      </Heading>
      {children}
    </section>
  );
};

export default WidgetGroup;
