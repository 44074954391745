import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  GaugeContainer,
  GaugeReferenceArc,
  GaugeValueArc,
  useGaugeState
} from '@mui/x-charts/Gauge';
import dayjs, { Dayjs } from 'dayjs';
import styles from './Savings.module.css';
import authService from '../../api-authorization/AuthorizeService';
import RequestOverlay from '../../components/RequestOverlay';

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const GaugePointer: React.FC = () => {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle)
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="black" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="black"
        strokeWidth={3}
      />
    </g>
  );
};

interface ISavingsGaugeAndStatsParams {
  title: string;
  gauge: {
    value: number;
    valueLabel: string;
    endLabel: string;
  };
  stats: {
    label: string;
    value: string;
  }[];
}

const SavingsStatsAndGauge: React.FC<ISavingsGaugeAndStatsParams> = ({
  title,
  stats,
  gauge
}) => (
  <Box sx={{ mr: { xs: 0, md: 2 }, mb: 2, width: { xs: '100%', md: 320 } }}>
    <Paper>
      <Box padding={2}>
        <Stack>
          <Typography variant="h5">{title}</Typography>
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              width: '288px',
              textAlign: 'center'
            }}
          >
            <GaugeContainer
              width={264}
              height={264}
              startAngle={-110}
              endAngle={110}
              value={gauge.value}
            >
              <GaugeReferenceArc />
              <GaugeValueArc />
              <GaugePointer />
            </GaugeContainer>
            <Box
              sx={{
                position: 'absolute',
                left: '17%',
                top: '81%',
                transform: 'translate(-50%,0)'
              }}
            >
              <Typography variant="h6" fontWeight="normal">
                0
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: '83%',
                top: '81%',
                minWidth: '120px',
                transform: 'translate(-50%,0)'
              }}
            >
              <Typography variant="h6" fontWeight="normal">
                {gauge.endLabel}
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '69%',
                transform: 'translate(-50%,0)'
              }}
            >
              <Typography variant="h6">{gauge.valueLabel}</Typography>
            </Box>
          </Box>
          <Stack width={320} spacing={2}>
            {stats.map((stat, index) => (
              <Stack key={index}>
                <Typography>{stat.label}:</Typography>
                <Typography variant="h5">{stat.value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Paper>
  </Box>
);

type SavingsResponse = {
  totalCoverageAreaSprayedAcres: number;
  mixCostUsdPerGallon: number;
  totalPesticideSprayedGallons: number;
  totalBroadcastPesticideSprayedGallons: number;
};

const Savings: React.FC = () => {
  const [savings, setSavings] = useState<SavingsResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [useRange, setUseRange] = useState(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(
    dayjs(new Date().getFullYear() - 1 + '-01-01')
  );
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));
  const dateFormat = 'MM/DD/YYYY';

  const fetchSavings = async () => {
    if (
      useRange &&
      (!dateFrom || !dateTo || !dateFrom?.isValid() || !dateTo?.isValid())
    ) {
      setSavings(null);
      return;
    }
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/savings` +
          (useRange
            ? `?dateFrom=${dateFrom?.format(dateFormat)}&dateTo=${dateTo?.format(dateFormat)}`
            : ''),
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const savingsData = await response.json();
      setSavings(savingsData);
      setLoading(false);
      setError(false);
    } catch (error) {
      console.log('error', error);
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchSavings();
  }, [useRange, dateFrom, dateTo]);

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseRange(event.target.value === 'date-range');
  };

  return (
    <>
      <RequestOverlay
        isLoading={false}
        error={error}
        id="loading-overlay"
        errorTitle="Your data couldn't be loaded."
        errorSubtitle="Please contact an administrator."
      />
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Typography variant="h4">Savings</Typography>
        <FormControl>
          <RadioGroup
            row
            value={useRange ? 'date-range' : 'lifetime'}
            onChange={handleRangeChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="lifetime"
              control={<Radio />}
              label="Lifetime"
            />
            <FormControlLabel
              value="date-range"
              control={<Radio />}
              label="Date Range"
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      {useRange && (
        <Box sx={{ mb: 2, maxWidth: { sm: '100%', md: 656 } }}>
          <Paper>
            <Box padding={2}>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <FormControl>
                  <FormLabel>From:</FormLabel>
                  <DatePicker value={dateFrom} onChange={setDateFrom} />
                </FormControl>
                <FormControl>
                  <FormLabel>To:</FormLabel>
                  <DatePicker value={dateTo} onChange={setDateTo} />
                </FormControl>
              </Stack>
            </Box>
          </Paper>
        </Box>
      )}
      {savings !== null &&
        savings.totalBroadcastPesticideSprayedGallons === 0 &&
        savings.totalCoverageAreaSprayedAcres === 0 &&
        savings.totalPesticideSprayedGallons === 0 && (
          <Alert severity="info" sx={{ mb: 2 }}>
            No data found{useRange && ' for selected date range'}.
          </Alert>
        )}
      {savings !== null && (
        <Box>
          <Box sx={{ mb: 2, maxWidth: { sm: '100%', md: 656 } }}>
            <Paper>
              <Stack
                direction="row"
                justifyContent="space-between"
                padding={2}
                spacing={2}
              >
                <Box width="288px">
                  <Typography>Area Covered:</Typography>
                  <Typography variant="h5">
                    {savings.totalCoverageAreaSprayedAcres} acres
                  </Typography>
                </Box>
                <Box width="288px">
                  <Typography>Average price / gal: </Typography>
                  <Typography variant="h5">
                    ${savings.mixCostUsdPerGallon.toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Box>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <SavingsStatsAndGauge
              title="Money Saved"
              gauge={{
                value:
                  (100 *
                    (savings.totalBroadcastPesticideSprayedGallons -
                      savings.totalPesticideSprayedGallons)) /
                  savings.totalBroadcastPesticideSprayedGallons,
                valueLabel:
                  '$' +
                  numberFormatter.format(
                    savings.mixCostUsdPerGallon *
                      (savings.totalBroadcastPesticideSprayedGallons -
                        savings.totalPesticideSprayedGallons)
                  ),
                endLabel:
                  '$' +
                  numberFormatter.format(
                    savings.mixCostUsdPerGallon *
                      savings.totalBroadcastPesticideSprayedGallons
                  )
              }}
              stats={[
                {
                  label: 'Cost of saved herbicide',
                  value:
                    '$' +
                    numberFormatter.format(
                      savings.mixCostUsdPerGallon *
                        (savings.totalBroadcastPesticideSprayedGallons -
                          savings.totalPesticideSprayedGallons)
                    )
                },
                {
                  label: 'Cost of broadcast herbicide',
                  value:
                    '$' +
                    numberFormatter.format(
                      savings.mixCostUsdPerGallon *
                        savings.totalBroadcastPesticideSprayedGallons
                    )
                },
                {
                  label: 'Cost of actual herbicide',
                  value:
                    '$' +
                    numberFormatter.format(
                      savings.mixCostUsdPerGallon *
                        savings.totalPesticideSprayedGallons
                    )
                }
              ]}
            />
            <SavingsStatsAndGauge
              title="Herbicide Saved"
              gauge={{
                value:
                  (100 *
                    (savings.totalBroadcastPesticideSprayedGallons -
                      savings.totalPesticideSprayedGallons)) /
                  savings.totalBroadcastPesticideSprayedGallons,
                valueLabel:
                  numberFormatter.format(
                    savings.totalBroadcastPesticideSprayedGallons -
                      savings.totalPesticideSprayedGallons
                  ) + ' gal',
                endLabel:
                  numberFormatter.format(
                    savings.totalBroadcastPesticideSprayedGallons
                  ) + ' gal'
              }}
              stats={[
                {
                  label: 'Herbicide saved',
                  value:
                    numberFormatter.format(
                      savings.totalBroadcastPesticideSprayedGallons -
                        savings.totalPesticideSprayedGallons
                    ) + ' gal'
                },
                {
                  label: 'Broadcast herbicide sprayed',
                  value:
                    numberFormatter.format(
                      savings.totalBroadcastPesticideSprayedGallons
                    ) + ' gal'
                },
                {
                  label: 'Actual herbicide sprayed',
                  value:
                    numberFormatter.format(
                      savings.totalPesticideSprayedGallons
                    ) + ' gal'
                }
              ]}
            />
          </Stack>
        </Box>
      )}
    </>
  );
};

export default Savings;
