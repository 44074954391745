import React, { useState } from 'react';
import BarChartWidget from '../widgets/BarChartWidget';

import styles from './AssetManagement.module.scss';
import { CHART_LEGEND_COLORS } from '../../constants/charts';
import { IProperties } from '../FilterPanel/interface';
import { getDefaultDateFilterRange } from '../../utils/dateFormat';
import RunsFilterPanel from '../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import usePerfomanceBreakdown from '../../hooks/usePerfomanceBreakdown';

const PerfomanceBreakdownInfo: React.FC = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading } = usePerfomanceBreakdown(properties);

  const totalDurations: number[] | undefined = data?.map(
    (el) => el.totalDuration
  );

  const totalIdsleTime: number[] | undefined = data?.map(
    (el) => el.totalIdleTime
  );

  const totalSprayingTime: number[] | undefined = data?.map(
    (el) => el.totalSprayingTime
  );

  const dates: string[] | undefined = data?.map((el) => el.date);

  return (
    <BarChartWidget
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      className={styles.widgetItem}
      title="📊 Performance Breakdown"
      description="Shows daily performance breakdown."
      subtitle="hr"
      data={{
        labels: dates || [],
        datasets: [
          {
            label: 'Duration', // First bar for each day
            data: totalDurations || [],
            backgroundColor: CHART_LEGEND_COLORS.orange
          },
          {
            label: 'Idle time', // Second bar for each day
            data: totalIdsleTime || [],
            backgroundColor: CHART_LEGEND_COLORS.blue
          },
          {
            label: 'Spraying time', // Third bar for each day
            data: totalSprayingTime || [],
            backgroundColor: CHART_LEGEND_COLORS.green
          }
        ]
      }}
    />
  );
};

export default PerfomanceBreakdownInfo;
