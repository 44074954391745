import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import styles from '../FilterPanel.module.scss';
import Text from '../../typographies/Text/Text';
import SavingsFilter from './SavingsFilter';
import FiltrationChip from '../FiltrationChip';
import { IProperties } from './interface';

/**
 * FilterPanel component needs to filter and export data.
 *
 * @example
 * <FilterPanel
 *  properties={properties}
 *  setProperties={setPproperties}
 *  handleExportFile={handleExportFile}
 *  loading={loading} />
 */

interface IDateFilterOptionProps {
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
  handleExportFile: () => Promise<void>;
  loading: boolean;
}

const SavingsFilterPanel: React.FC<IDateFilterOptionProps> = ({
  properties,
  setProperties,
  handleExportFile,
  loading
}) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const onFilterToggle = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setFilterOpen(false);
  };

  const clearProperty = (propertyName: string) => {
    setProperties((prev) => {
      return {
        ...prev,
        [propertyName]: ''
      };
    });
  };

  const appliedFilters = Object.entries(properties).filter(
    ([, value]) => value
  );
  const appliedFiltersCount = appliedFilters.length;

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <div className={styles.filterPanelWrapper}>
        <div className={styles.filterPanel}>
          <Text tag="button" onClick={onFilterToggle}>
            <img src="/images/filter_alt.svg" />
            Filter
            <span>{appliedFiltersCount > 0 && `• ${appliedFiltersCount}`}</span>
          </Text>
          <div className={styles.slash}></div>
          <Text tag="button" onClick={handleExportFile} isLoading={loading}>
            <img src="/images/download.svg" />
            Export all savings section
          </Text>
          {filterOpen ? (
            <SavingsFilter
              open={filterOpen}
              properties={properties}
              setProperties={setProperties}
            />
          ) : null}
        </div>
        {appliedFiltersCount ? (
          <div className={styles.filtrationChipWrapper}>
            {appliedFilters.map(([key, value]) => (
              <FiltrationChip
                key={key}
                filtration={value}
                onDelete={key !== 'date' ? () => clearProperty(key) : undefined}
              />
            ))}
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SavingsFilterPanel;
