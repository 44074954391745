import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOverlay, Loader, ActionIcon } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import coffee from '../../../images/coffee.png';
import styles from './RequestOverlay.module.css';
import { leaveswhite, logo, logowhite } from '../../../images';

const CustomLoader = ({
  isLoading,
  error,
  errorTitle = 'Something went wrong.',
  errorSubtitle = "Sorry, we couldn't find your page."
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const reload = () => {
    window.location.reload();
  };

  if (error)
    return (
      <div className="max-w-[50rem] flex flex-col mx-auto w-full h-full">
        <header className="mb-auto flex-col justify-center align-center z-50 w-full py-4">
          <nav className="px-4 sm:px-6 lg:px-8" aria-label="Global">
            {/* <a
              className="flex-none text-xl font-semibold sm:text-3xl"
              href="#"
              aria-label="Brand"
            >
              Oops!
            </a> */}
          </nav>
          {/* <img src={logo} alt="" height="200px" width="200px" /> */}
        </header>
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8 pt-0 flex justify-center items-center flex-col">
          <a
            className="flex-none text-xl font-semibold sm:text-3xl mb-4"
            href="#"
            aria-label="Brand"
          >
            Oops!
          </a>
          <img src={logo} alt="" height="120px" width="40%" className="mb-4" />

          <h1 className="block text-2xl font-bold text-white" />
          <p className="mt-3 font-extrabold">{errorTitle}</p>
          <p className="font-extrabold">{errorSubtitle}</p>

          <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
            <span
              onClick={reload}
              className="cursor-pointer w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-green-600 hover:bg-green-700 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800"
              target="_blank"
            >
              {/* <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
              </svg> */}
              <img
                src={leaveswhite}
                className="w-4 h-4"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
                alt=""
              />
              Reload
            </span>
            <span
              onClick={goBack}
              className="cursor-pointer w-full sm:w-auto inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-green-600 hover:text-green-800 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm py-3 px-4 dark:ring-offset-slate-900"
            >
              <svg
                className="w-2.5 h-2.5"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M11.2792 1.64001L5.63273 7.28646C5.43747 7.48172 5.43747 7.79831 5.63273 7.99357L11.2792 13.64"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
              Go back
            </span>
          </div>

          {/* <ActionIcon
            color="teal"
            size="xl"
            radius="xl"
            className="m-auto mt-3"
          >
            <IconRefresh size="2.125rem" />
          </ActionIcon> */}
        </div>
        <footer className="mt-auto text-center py-5">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"></div>
        </footer>
      </div>
    );

  if (isLoading) return <Loader color="blue" />;
};

export default function RequestOverlay({
  overlayBlur = 20,
  isLoading,
  error,
  id,
  errorTitle,
  errorSubtitle
}) {
  const [pageStuck, setPageStuck] = useState(false);

  useEffect(() => {
    let tm;
    if (isLoading) {
      tm = setTimeout(() => {
        setPageStuck(true);
      }, 8000);

      if (!isLoading) setPageStuck(false);
    }
    return () => clearTimeout(tm);
  }, [isLoading]);

  return (
    <LoadingOverlay
      id={id}
      className="z-20"
      visible={isLoading || error || (isLoading && pageStuck)}
      overlayBlur={overlayBlur}
      loader={
        <CustomLoader
          errorTitle={errorTitle}
          errorSubtitle={errorSubtitle}
          error={error}
          isLoading={isLoading}
        />
      }
    />
  );
}
