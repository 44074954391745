import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

const AddClientDialog = ({ open, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm();

  const addClient = async (data) => {
    const token = await authService.getAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/clients/add`,
        {
          method: 'POST',
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
          body: JSON.stringify(data)
        }
      );
      if (response.ok) {
        toast.success('Client added successfully!');
        onSuccess();
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (err) {
      toast.error('Error: ' + err);
      console.log('err', err);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(addClient)}>
        <DialogTitle>
          <AddIcon /> Add Client
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormLabel>Client Name</FormLabel>
              <TextField autoFocus {...register('name')} />
            </FormControl>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <TextField {...register('primaryContactEmail')} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <TextField {...register('primaryContactPhone')} />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="outlined" disabled={loading}>
            Add Client
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddClientDialog;
