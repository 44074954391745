import React, { useState } from 'react';
import BarChartWidget from '../widgets/BarChartWidget';
import { getDate, getDefaultDateFilterRange } from '../../utils/dateFormat';
import { IProperties } from '../FilterPanel/RunsFilterPanel/interface';
import RunsFilterPanel from '../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import useRunsInfo from '../../hooks/useRunsInfo';
import { CHART_LEGEND_COLORS } from '../../constants/charts';

const RunsInfo = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading } = useRunsInfo(properties);

  const totalDayRunsAmount: number[] | undefined = data?.map(
    (el) => el.totalDayRunsAmount
  );

  const totalPesticideSavedGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSavedGallons
  );

  const totalPesticideSprayedGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSprayedGallons
  );

  return (
    <BarChartWidget
      title="📊 Runs Info"
      description="Shows data for the runs, herbicide sprayed, and herbicide saved."
      subtitle="gal"
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      data={{
        labels: getDate(data),
        datasets: [
          {
            label: 'Runs', // First bar for each day
            data: totalDayRunsAmount || [],
            backgroundColor: CHART_LEGEND_COLORS.orange
          },
          {
            label: 'Herbicide sprayed', // Second bar for each day
            data: totalPesticideSprayedGallons || [],
            backgroundColor: CHART_LEGEND_COLORS.blue
          },
          {
            label: 'Herbicide saved', // Third bar for each day
            data: totalPesticideSavedGallons || [],
            backgroundColor: CHART_LEGEND_COLORS.green
          }
        ]
      }}
    />
  );
};

export default RunsInfo;
