import Sidebar from '../../../components/Sidebar';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import authService from '../../../api-authorization/AuthorizeService';
import toast from 'react-hot-toast';
import validator from 'validator';
import styles from './UserManagementContent.module.css';

export default function UserManagement() {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [rolesLoadingError, setRolesLoadingError] = useState(false);
  const [error, setError] = useState(false);
  const [selectedEmailToDelete, setSelectedEmailToDelete] = useState('');
  const [selectedEmailToUpdate, setSelectedEmailToUpdate] = useState('');

  const [selectedEmailRoles, setSelectedEmailRoles] = useState({
    Manager: false,
    Operator: false
  });

  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorRolesMessage, setErrorRolesMessage] = useState('');

  const [updateRolesChanged, setUpdateRolesChanged] = useState(false);

  const [emailToInvite, setEmailToInvite] = useState('');
  const [roles, setRoles] = useState([]);
  const [update, setUpdate] = useState(0);

  const [changeFirstOption, setChangeFirstOption] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = await authService.getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users/get-all-user-emails`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          }
        );
        const data = await response.json();
        setData(data);
        setLoading(false);
        setError(false);
      } catch (error) {
        setTimeout(() => {
          setError(true);
          setLoading(false);
        }, 1000);
      }
    };
    fetchUsers();
  }, [update]);

  const confirmDeleteUser = () => {
    if (!selectedEmailToDelete) return;
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this user?')) {
      handleDeleteUser();
    } else {
    }
  };

  const handleDeleteUser = async () => {
    if (!selectedEmailToDelete) {
      return;
    } else {
      setLoading(true);
      const token = await authService.getAccessToken();
      const data = {
        clientId: selectedEmailToDelete
      };
      const requestOptions = {
        method: 'DELETE',
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
        body: JSON.stringify(data)
      };

      const fetchPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/users/delete-user?email=${selectedEmailToDelete}`,
            requestOptions
          )
            .then((response) => {
              if (!response.ok) {
                reject('Delete failed');
                setLoading(false);
                throw new Error('Delete failed');
              }

              resolve('Delete successful');
              setLoading(false);
              setUpdate(update + 1);
              setSelectedEmailToDelete('');
            })
            .catch((error) => {
              reject('Delete failed');
              setLoading(false);
              console.error('Delete failed:', error);
            });
        }, 1500);
      });

      toast.promise(fetchPromise, {
        loading: 'Loading...',
        success: 'Delete successful',
        error: 'Delete failed'
      });
    }
  };

  const inviteUser = async () => {
    if (!emailToInvite || roles.length === 0) {
      return;
    } else {
      setLoading(true);
      const data = {
        email: emailToInvite,
        roles: roles
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      const fetchPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/users/invite-user`,
            requestOptions
          )
            .then((response) => {
              if (!response.ok) {
                reject(response.status);
                setLoading(false);
                throw new Error(response.status);
              }

              resolve('Invite successful');

              document.getElementById('manager').checked = false;
              document.getElementById('operator').checked = false;

              setLoading(false);
              setEmailToInvite('');
            })
            .catch((error) => {
              reject('Invite failed');
              setLoading(false);
            });
        }, 1500);
      });

      toast.promise(fetchPromise, {
        loading: 'Loading...',
        success: 'Invite successful',
        error: (error) => {
          return error === 500 ? 'User already exists!' : 'Invite failed';
        }
      });
    }
  };

  const handleRoleChange = (role) => (e) => {
    if (e.target.checked) {
      setRoles([...roles, role]);
    } else {
      setRoles(roles.filter((r) => r !== role));
    }
  };

  const handleInviteUser = () => {
    if (!validator.isEmail(emailToInvite)) {
      return setErrorEmailMessage('Please enter a valid email address.');
    } else if (roles.length === 0) {
      setErrorEmailMessage('');
      return setErrorRolesMessage('Please select at least one role.');
    }

    setErrorRolesMessage('');

    inviteUser();
  };

  const handleEmailToUpdateChange = async (e) => {
    const email = e.target.value;
    setSelectedEmailToUpdate(email);

    const token = await authService.getAccessToken();
    const requestOptions = {
      method: 'GET',
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
    };

    setRolesLoading(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/users/get-roles?email=${email}`,
      requestOptions
    )
      .then(async (response) => {
        const result = await response.json();

        if (!response.ok) {
          setRolesLoadingError('Failed to load user roles');
          return toast.error('Failed to load user roles');
        }

        setRolesLoading(false);
        let newRolesState = {};
        for (let index = 0; index < result.length; index++) {
          newRolesState[result[index]] = true;
        }

        setRolesLoadingError('');
        setSelectedEmailRoles(newRolesState);
        setRolesLoading(false);
      })
      .catch((error) => {
        setRolesLoadingError('Failed to load user roles');
        return toast.error('Failed to load user roles');
      });
  };

  const handleRolesCheckboxChange = (role) => (e) => {
    setUpdateRolesChanged(true);

    setSelectedEmailRoles({
      ...selectedEmailRoles,
      [role]: e.target.checked
    });
  };

  const handleSaveRoles = async () => {
    setUpdateRolesChanged(false);

    let newRoles = [];
    for (const [key, value] of Object.entries(selectedEmailRoles)) {
      if (value) newRoles.push(key);
    }

    if (newRoles.length === 0)
      return toast.error('Please select at least one role.');

    const token = await authService.getAccessToken();
    const data = {
      email: selectedEmailToUpdate,
      roles: newRoles
    };
    const requestOptions = {
      method: 'PATCH',
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
      body: JSON.stringify(data)
    };

    setLoading(true);

    const fetchPromise = new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/update-roles?email=${selectedEmailToUpdate}`,
        requestOptions
      )
        .then((response) => {
          setLoading(false);

          if (!response.ok) {
            setUpdateRolesChanged(true);
            reject('Update failed');
          }

          resolve('Update succeeded');
        })
        .catch((error) => {
          setUpdateRolesChanged(true);
          setLoading(false);
          reject('Update failed');
        });
    });

    toast.promise(fetchPromise, {
      loading: 'Loading...',
      success: 'Update successful',
      error: 'Update failed'
    });
  };

  return (
    <>
      <div className="w-full">
        <div className={styles.container}>
          <div className="space-y-12 w-full">
            <div className="border-b border-gray-900/10 pb-4 w-full">
              <h2 className="font-semibold leading-7 text-gray-900 text-xl pb-4">
                Invite New User
              </h2>

              <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email:
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(e) => setEmailToInvite(e.target.value)}
                      value={emailToInvite}
                      type="email"
                      id="UserEmail"
                      placeholder="user@agtechlogic.com"
                      className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300"
                    />

                    {errorEmailMessage && (
                      <p className="text-xs text-red-500">
                        {errorEmailMessage}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-full">
                  <fieldset>
                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                      Roles:
                    </legend>
                    <div className="mt-6 space-y-6">
                      <div className="flex items-center gap-x-3">
                        <input
                          onChange={handleRoleChange('Manager')}
                          id="manager"
                          name="manager"
                          type="checkbox"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <div className="text-sm leading-6">
                          <label
                            htmlFor="manager"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Manager
                          </label>
                          <p className="text-gray-500">Managerial access</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          onChange={handleRoleChange('Operator')}
                          id="operator"
                          name="operator"
                          type="checkbox"
                          className="h-4 w-4 border-gray-300   text-indigo-600 focus:ring-indigo-600"
                        />
                        <div className="text-sm leading-6">
                          <label
                            htmlFor="operator"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Operator
                          </label>
                          <p className="text-gray-500">Employee access</p>
                        </div>
                      </div>
                    </div>

                    {errorRolesMessage && (
                      <p className="text-xs text-red-500">
                        {errorRolesMessage}
                      </p>
                    )}
                  </fieldset>
                </div>
              </div>

              <div className="mt-4 flex items-center justify-end gap-x-6">
                <button type="button" className={styles.buttonCancelContainer}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={styles.buttonSubmitContainer}
                  onClick={handleInviteUser}
                >
                  Invite
                </button>
              </div>
            </div>

            <div className="border-b border-gray-900/10 pb-4 w-full">
              <div className=" pb-12">
                <h2 className="font-semibold leading-7 text-gray-900  text-xl pb-4 pt-8">
                  Update Roles
                </h2>

                <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email:
                    </label>
                    <div className="mt-2">
                      <select
                        value={selectedEmailToUpdate}
                        onChange={handleEmailToUpdateChange}
                        id="email"
                        name="email"
                        autoComplete="email"
                        className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300"
                      >
                        <option
                          key="empty"
                          value=""
                          disabled
                        >{`---------------------------------Select Email---------------------------------`}</option>

                        {data?.map((email) => (
                          <option key={email} value={email}>
                            {email}
                          </option>
                        ))}
                      </select>
                      {rolesLoadingError && (
                        <p className="text-xs text-red-500 mt-2">
                          {rolesLoadingError}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <fieldset>
                      <legend className="text-sm font-semibold leading-6 text-gray-900">
                        Roles:
                      </legend>
                      <div className="mt-6 space-y-6 relative">
                        <LoadingOverlay
                          visible={rolesLoading}
                          overlayBlur={2}
                        />
                        <div className="flex items-center gap-x-3">
                          <input
                            id="role-manager"
                            name="role-manager"
                            type="checkbox"
                            disabled={!selectedEmailToUpdate}
                            checked={selectedEmailRoles.Manager}
                            onChange={handleRolesCheckboxChange('Manager')}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <div className="text-sm leading-6">
                            <label
                              htmlFor="role-manager"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Manager
                            </label>
                            <p className="text-gray-500">Managerial access</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="role-operator"
                            name="role-operator"
                            type="checkbox"
                            disabled={!selectedEmailToUpdate}
                            checked={selectedEmailRoles.Operator}
                            onChange={handleRolesCheckboxChange('Operator')}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <div className="text-sm leading-6">
                            <label
                              htmlFor="role-operator"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Operator
                            </label>
                            <p className="text-gray-500">Employee access</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="mt-4 flex items-center justify-end gap-x-6">
                <button type="button" className={styles.buttonCancelContainer}>
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!updateRolesChanged}
                  className={styles.buttonSubmitContainer}
                  onClick={handleSaveRoles}
                >
                  Update
                </button>
              </div>
            </div>
            <div className="border-b border-gray-900/10 pb-4 w-full">
              <h2 className="font-semibold leading-7 text-gray-900 text-xl pb-4 pt-8">
                Remove User
              </h2>

              <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email:
                  </label>
                  <div className="mt-2">
                    <select
                      id="emailToDelete"
                      name="emailToDelete"
                      autoComplete="delete-email"
                      value={selectedEmailToDelete}
                      className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300"
                      onChange={(e) => setSelectedEmailToDelete(e.target.value)}
                    >
                      <option key="empty" value="" disabled>
                        {changeFirstOption
                          ? 'New First Option'
                          : '---------------------------------Select Email---------------------------------'}
                      </option>

                      {data?.map((email) => (
                        <option key={email} value={email}>
                          {email}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-span-full"></div>
              </div>

              <div className="mt-4 flex items-center justify-end gap-x-6">
                <button type="button" className={styles.buttonCancelContainer}>
                  Cancel
                </button>
                <button
                  disabled={!selectedEmailToDelete}
                  onClick={confirmDeleteUser}
                  type="submit"
                  className={styles.buttonSubmitContainer}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
