import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Skeleton } from '@mui/material';
import styles from './WidgetCard.module.scss';
import filterPanelStyles from '../FilterPanel/FilterPanel.module.scss';
import Text from '../typographies/Text/Text';
import Heading from '../typographies/Heading/Heading';
import FiltrationChip from '../FilterPanel/FiltrationChip';
import { IProperties } from '../FilterPanel/interface';
import { exportToFileFromElement } from '../../utils/file-export-utils';

/**
 * WidgetCard component needs to reuse and show widget data.
 *
 * @example
 *  <WidgetCard title="Your title" description="Your descr">
 *    Some children data
 *  </WidgetCard>
 */

export interface IWidgetCardProps {
  children: React.ReactNode;
  title: string;
  description: string;
  properties?: IProperties;
  setProperties?: React.Dispatch<React.SetStateAction<IProperties>>;
  className?: string;
  id?: string;
  isLoading?: boolean;
  filter?: React.ReactNode;
  isExport?: boolean;
}

const WidgetCard: React.FC<IWidgetCardProps> = ({
  children,
  title,
  description,
  properties,
  setProperties,
  className,
  id,
  filter,
  isExport = false,
  isLoading = false
}) => {
  const printRef = useRef<HTMLDivElement | null>(null);

  const [loadingExport, setLoadingExport] = useState(false);

  const appliedFilters =
    properties && Object.entries(properties).filter(([, value]) => value);

  const clearProperty = (propertyName: string) => {
    setProperties?.((prev) => {
      return {
        ...prev,
        [propertyName]: ''
      };
    });
  };

  const handleExportFile = async () => {
    await exportToFileFromElement('pdf', printRef, setLoadingExport);
  };

  return isLoading ? (
    <Skeleton className={styles.widgetCard} variant="rounded" />
  ) : (
    <div ref={printRef} id={id} className={clsx(styles.widgetCard, className)}>
      <div className={styles.header}>
        <Heading level={3} className={styles.title}>
          {title}
          <div className={filterPanelStyles.filterButtonGroupWrapper}>
            <div className={filterPanelStyles.filterPanel}>
              {filter}
              {isExport ? (
                <Text
                  tag="button"
                  onClick={handleExportFile}
                  isLoading={loadingExport}
                >
                  <img src="/images/download.svg" />
                  Export
                </Text>
              ) : null}
            </div>
          </div>
        </Heading>
        <Text type="label" className={styles.description}>
          {description}
        </Text>
      </div>
      {appliedFilters?.length && clearProperty ? (
        <div className={styles.filtrationChipWrapper}>
          {appliedFilters.map(([key, value]) => (
            <FiltrationChip
              key={key}
              filtration={value}
              onDelete={key !== 'date' ? () => clearProperty(key) : undefined}
            />
          ))}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default WidgetCard;
