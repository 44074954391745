import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField
} from '@mui/material';
import authService from '../../api-authorization/AuthorizeService';
import toast from 'react-hot-toast';

interface ISendImageDialogProps {
  open: boolean;
  imageCapture: {
    id: string;
  };
  onClose: () => void;
  onSuccess: () => void;
}

interface userIdAndEmail {
  id: string;
  email: string;
}

const SendImageDialog: React.FC<ISendImageDialogProps> = ({
  imageCapture,
  open,
  onClose,
  onSuccess
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<userIdAndEmail[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [isSendButtonDisabled, setIsSendButtonDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setIsSendButtonDisabled(selected.length === 0);
  }, [selected]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/get-all-user-ids-and-emails`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      if (response.ok) {
        const userData = await response.json();
        setUsers(userData);
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
    setLoading(false);
  };

  const getUsersToDisplay = () => {
    const selectedUsers = users.filter((user) => selected.includes(user.id));
    const filteredAndUnselectedUsers = users.filter(
      (user) => user.email.includes(filter) && !selected.includes(user.id)
    );
    return filter === ''
      ? selectedUsers.concat(filteredAndUnselectedUsers)
      : filteredAndUnselectedUsers;
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleSelectUser = (user: userIdAndEmail) => {
    const currentIndex = selected.indexOf(user.id);
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(user.id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    setFilter('');
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;
    }
  };

  const sendImage = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/add`,
        {
          method: 'POST',
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
          body: JSON.stringify({
            imageCaptureId: imageCapture.id,
            userIds: selected
          })
        }
      );
      if (response.ok) {
        toast.success('Image sent successfully!');
        onSuccess();
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} sx={{ '& .MuiDialog-paper': { minWidth: '300px' } }}>
      <DialogTitle>
        <Stack direction="row" justifyContent={'space-between'}>
          <Box>Send Image</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '400px', width: '344px' }}
          >
            <CircularProgress></CircularProgress>
          </Stack>
        ) : (
          <Stack>
            <TextField
              placeholder="Search"
              variant="outlined"
              onChange={handleFilterChange}
              value={filter}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }
              }}
            />
            <Box ref={boxRef} sx={{ maxHeight: '344px', overflow: 'auto' }}>
              <List>
                {getUsersToDisplay().map((user, index) => (
                  <ListItem key={index} sx={{ px: 0 }}>
                    <ListItemButton
                      onClick={() => handleSelectUser(user)}
                      dense
                    >
                      <ListItemText primary={user.email} />
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <Checkbox
                          edge="end"
                          checked={selected.includes(user.id)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={isSendButtonDisabled}
          onClick={sendImage}
        >
          Send{selected.length > 1 ? ' separately' : ''}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendImageDialog;
