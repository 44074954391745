import React, { useState } from 'react';
import LineChartWidget from '../widgets/LineChartWidget';
import { IProperties } from '../FilterPanel/interface';
import { getDate, getDefaultDateFilterRange } from '../../utils/dateFormat';
import RunsFilterPanel from '../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import useSavingsAllBlocks from '../../hooks/useSavingsAllBlocks';

const SavingsAllBlocks = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading } = useSavingsAllBlocks(properties);

  const totalPesticideSavingAmountGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSavingAmountGallons
  );

  return (
    <LineChartWidget
      title="📊 Herbicide Savings (All Blocks)"
      description="Herbicide saved trend (gal)"
      subtitle="gal"
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      data={{
        labels: getDate(data),
        datasets: [
          {
            data: totalPesticideSavingAmountGallons || []
          }
        ]
      }}
    />
  );
};

export default SavingsAllBlocks;
