import React from 'react';
import styles from './Breadcrumbs.module.scss';
import { Link } from 'react-router-dom';
import useCustomBreadcrumbs from '../../../hooks/useBreadcrumbsHook';
import Text from '../typographies/Text/Text';

/**
 * Breadcrumbs component needs to show navigation between pages.
 *
 * @example
 * <Breadcrumbs />
 */

const Breadcrumbs = () => {
  const breadcrumbs = useCustomBreadcrumbs();
  return (
    <ol className={styles.breadcrumbs}>
      {breadcrumbs.map((item, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <Text
            key={item.key}
            tag="li"
            type="label"
            className={isLast ? styles.current : styles.prev}
          >
            <Link to={item.key}>{item.breadcrumb}</Link>
          </Text>
        );
      })}
    </ol>
  );
};

export default Breadcrumbs;
