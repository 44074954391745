import React from 'react';

import Text from '../typographies/Text/Text';
import styles from './Legend.module.scss';

interface Props {
  title: string;
}

const Legend: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.container}>
      <span className={styles.legendIndicator} />
      <Text type="caption-medium">{title}</Text>
    </div>
  );
};

export default Legend;
