import React from 'react';
import styles from './PageTitle.module.scss';
import BackButton from '../BackButton/BackButton';
import Heading from '../typographies/Heading/Heading';

/**
 * PageTitle component needs to show page title with back button.
 *
 * @example
 * <PageTitle />
 */

const PageTitle = () => {
  return (
    <div className={styles.pageTitle}>
      <BackButton />
      <Heading level={1}>📡 Command Center</Heading>
    </div>
  );
};

export default PageTitle;
