import React from 'react';
import styles from '../FilterPanel.module.scss';
import FilterOption from '../FilterOption';
import DateFilterOption from '../DateFilterOption';
import { IProperties } from './interface';
import useSWR from 'swr';
import { LOCATIONS_API_ENDPOINTS } from '../../../constants/api';
import { fetchBlocks } from '../../../utils/api-utils';

/**
 * Filter component needs to filter and export data.
 *
 * @example
 * <Filter
 *    open={isOpen}
 *    properties={properties}
 *    setProperties={setProperties}
 *  />
 */

interface IFilterProps {
  open: boolean;
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const SavingsFilter: React.FC<IFilterProps> = ({
  open,
  properties,
  setProperties
}) => {
  const { data, isLoading } = useSWR(`${LOCATIONS_API_ENDPOINTS.blocks}`, () =>
    fetchBlocks()
  );

  return (
    <div className={open ? styles.filter : styles.filterClosed}>
      <DateFilterOption properties={properties} setProperties={setProperties} />
      <FilterOption
        iconPath="/images/plant.svg"
        title="Block"
        propertyName="block"
        options={data || []}
        isLoading={isLoading}
        properties={properties}
        setProperties={setProperties}
      />
    </div>
  );
};

export default SavingsFilter;
