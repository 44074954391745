import React from 'react';
import NavCard from '../../components/NavCard';
import {
  agronomist,
  crophealth,
  drone,
  myaccount,
  commandcenter,
  precisionspray,
  getaquote,
  uatv,
  support,
  todolist,
  water,
  weather,
  savings,
  climate
} from '../../../images';
import styles from './Home.module.css';
import { useDispatch, useSelector } from 'react-redux';

export default function Home() {
  const isManager = useSelector((state) => state.app.isManager);
  const isAdmin = useSelector((state) => state.app.isAdmin);
  const [userType, setUserType] = React.useState('');
  const user = useSelector((state) => state.app.user);
  const handleGetQuoteClick = () => {
    const externalLink =
      'https://share.hsforms.com/1wRQpAhgdS9aIsiOHaL0xbAdumbq';
    setTimeout(() => {
      window.open(externalLink, '_blank');
    }, 250);
  };
  const blockedRoute = !isManager && !isAdmin;

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <div>
          <NavCard
            title="Precision Spray"
            actionText="Get Started"
            icon={precisionspray}
            link="/precision-spray"
          />
        </div>
        <div>
          <NavCard
            title="Command Center"
            actionText="Get Started"
            icon={commandcenter}
            link="/command-center"
            disabled={blockedRoute}
          />
        </div>
        <div>
          <NavCard
            title="Aerial Spray Drone"
            actionText="Coming Soon"
            icon={drone}
            link="/aerial-spray-drone"
            titleClassName={styles.aerial}
          />
        </div>
        <div>
          <NavCard
            title="Automation"
            actionText="Coming Soon"
            icon={uatv}
            link="/automation"
          />
        </div>
        <div>
          <NavCard
            title="Savings"
            actionText="Get Started"
            icon={savings}
            link="/savings"
          />
        </div>
        <div>
          <NavCard
            title="Climate"
            actionText="Coming Soon"
            icon={climate}
            link="/climate"
          />
        </div>
        <div>
          <NavCard
            title="Tech Support"
            actionText="Get Started"
            icon={support}
            link="/tech-support"
          />
        </div>
        <div>
          <NavCard
            title="Agronomist"
            actionText="Coming Soon"
            icon={agronomist}
            link="/agronomist"
          />
        </div>
        <div>
          <NavCard
            title="To Do List"
            actionText="Coming Soon"
            icon={todolist}
            link="/to-do-list"
          />
        </div>
        <div style={{ cursor: 'pointer' }} onClick={handleGetQuoteClick}>
          <NavCard
            title="Get A Quote"
            actionText="Get Started"
            icon={getaquote}
          />
        </div>
        {/*<div id="invisible-home-icon"></div>*/}

        <div>
          <NavCard
            title="Weather"
            actionText="Coming Soon"
            icon={weather}
            link="/weather"
          />
        </div>

        <div>
          <NavCard
            title="My Account"
            actionText="Get Started"
            icon={myaccount}
            link="/settings/user-management"
          />
        </div>
      </div>
    </div>
  );
}
