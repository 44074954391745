import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    token: null,
    user: null,
    isAdmin: false,
    isManager: false,
    isDeveloper: false,
    isOperator: false,

    misc: {
      lastClickedTractor: null
    }
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload; // 2
    },

    changeName: (state, action) => {
      state.name = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },

    setLastClickedTractor: (state, action) => {
      console.log('action payload', action.payload);
      state.misc.lastClickedTractor = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;

      if (action.payload.role.includes('Admin')) {
        state.isAdmin = true;
      } else if (action.payload.role.includes('Manager')) {
        state.isManager = true;
      }

      if (action.payload.role.includes('Developer')) {
        state.isDeveloper = true;
      } else {
        state.isOperator = true;
      }
    },

    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.isAdmin = false;
      state.isManager = false;
    }
  }
});

export const {
  increment,
  decrement,
  incrementByAmount,
  changeName,
  setToken,
  setUser,
  logOut,
  setLastClickedTractor
} = appSlice.actions;

export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
export const selectCount = (state) => state.counter.value;

export default appSlice.reducer;
