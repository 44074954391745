import React from 'react';
import { DateRange } from 'react-day-picker';
import styles from './FilterPanel.module.scss';
import { format } from '../../../utils';
import { Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { dateFormatDayFirst } from '../../constants/dateFormat';
import { isDateRange } from '../../utils/dateFormat';

/**
 * FiltrationChip component needs to display and delete selected filter in chip.
 *
 * @example
 * <FiltrationChip
 *   filtration="applied filter"
 *   onDelete={() => {}}
 * />
 */

interface IFiltrationChipProps {
  filtration: DateRange | string | number | undefined;
  onDelete?: () => void;
}

const FiltrationChip: React.FC<IFiltrationChipProps> = ({
  filtration,
  onDelete
}) => {
  const formattedSelectedDateFrom = isDateRange(filtration)
    ? format(filtration.from, dateFormatDayFirst)
    : undefined;

  const formattedSelectedDateTo = isDateRange(filtration)
    ? format(filtration.to, dateFormatDayFirst)
    : undefined;

  return (
    <Chip
      className={styles.filtrationContainer}
      onDelete={onDelete}
      deleteIcon={<CloseIcon />}
      label={
        isDateRange(filtration)
          ? `${formattedSelectedDateFrom} - ${formattedSelectedDateTo}`
          : filtration
      }
    />
  );
};

export default FiltrationChip;
