import useSWR from 'swr';
import { IProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchRunsInfo } from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';

const useRunsInfo = ({ date }: IProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const runsInfoParams = {
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, isLoading, error } = useSWR(
    `${CC_API_ENDPOINTS.runsInfo}?${createQueryString(runsInfoParams)}`,
    () => fetchRunsInfo(runsInfoParams)
  );

  const { runs } = data || {};

  return {
    data: runs,
    isLoading,
    error
  };
};

export default useRunsInfo;
