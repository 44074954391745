import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BackButton.module.scss';
import Text from '../typographies/Text/Text';

/**
 * BackButton component needs to navigate user back.
 *
 * @example
 * <BackButton />
 */

const BackButton = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Text
      tag="button"
      type="button"
      className={styles.backButton}
      onClick={goBack}
    >
      <img src="/images/arrow_back_icon.svg" />
      Back
    </Text>
  );
};

export default BackButton;
