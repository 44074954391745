import React, { useMemo } from 'react';
import { SWRConfig } from 'swr';
import { AnimatePresence, motion } from 'framer-motion';
import AuthRoute from '../src/AuthRoute';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'animate.css';
import 'spinkit/spinkit.min.css';
import 'react-awesome-slider/dist/styles.css';
import './App.css';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components-container/api-authorization/AuthorizeRoute';
import Layout from './components-container/components/Layout';
import Header from './components-container/components/Header';
import Navbar from './components-container/components/Navbar';
import Breadcrumbs from './components-container/components/Breadcrumbs';
import NotFound from './components-container/pages/NotFound/NotFound';
import('preline');

const hideWrapperRoutes = ['/authentication', '/command-center'];

const fetcher = (token) => async (url) => {
  let cleanUrl = url;
  if (!url.startsWith('/')) {
    cleanUrl = '/' + url;
  }

  const res = await fetch(process.env.REACT_APP_BASE_URL + cleanUrl, {
    headers: !token ? {} : { Authorization: `Bearer ${token}` }
  });

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const Wrapper = () => {
  const location = useLocation();

  const hideWrapper = hideWrapperRoutes.some((route) =>
    location.pathname.includes(route)
  );

  if (hideWrapper && location.pathname !== '/home') return <Outlet />;

  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <Navbar />
      <Layout>
        <Breadcrumbs />
        <Header />
        <Outlet />
      </Layout>
    </motion.main>
  );
};

const theme = createTheme({ palette: { primary: { main: '#8fc25b' } } });

const App = () => {
  const location = useLocation();
  const token = useSelector((state) => state.app.token);

  const unprotected = location.pathname.includes('/authentication');
  const shouldShowLayout = !location.pathname.includes(
    '/settings/user-management'
  );

  const options = useMemo(
    () => ({
      // refreshInterval: 3000,
      fetcher: fetcher(token)
    }),
    [token]
  );

  return (
    <div id="global-container">
      <SWRConfig value={options}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <Toaster
              containerClassName="always-on-top"
              position="top-right"
              reverseOrder={false}
              duration={5000}
              className="no-select always-on-top"
            />

            <AnimatePresence mode="sync">
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Wrapper />}>
                  {AppRoutes.map((route, index) => {
                    const {
                      element,
                      requireAuth,
                      requireAdmin,
                      requireManager,
                      operatorProhibitedRoute,
                      ...rest
                    } = route;
                    return (
                      <Route
                        {...rest}
                        key={index}
                        element={
                          unprotected ? (
                            element
                          ) : (
                            <AuthRoute
                              requireAdmin={requireAdmin}
                              requireManager={requireManager}
                            >
                              <AuthorizeRoute {...rest} element={element} />
                            </AuthRoute>
                          )
                        }
                      />
                    );
                  })}
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AnimatePresence>
          </ThemeProvider>
        </LocalizationProvider>
      </SWRConfig>
    </div>
  );
};

export default App;
