import { ISavingsData, ISavingsParams } from '../interfaces/savings-interfaces';
import {
  CC_API_ENDPOINTS,
  LOCATIONS_API_ENDPOINTS,
  REPORTS_API_ENDPOINTS
} from '../constants/api';
import {
  IRunsSummaryData,
  IRunsSummaryParams
} from '../interfaces/runs-summary-interfaces';
import {
  IRunsInfoData,
  IRunsInfoParams
} from '../interfaces/runs-info-interfaces';
import {
  ISprayerInfoData,
  ISprayerInfoParams
} from '../interfaces/sprayer-info-interfaces';
import {
  ISubLocationInfoData,
  ISubLocationInfoParams
} from '../interfaces/sub-location-info-interfaces';
import {
  ISubLocationSprayedInfoData,
  ISubLocationSprayedInfoParams
} from '../interfaces/sub-location-sprayed-info-interfaces';
import {
  IPerfomanceInfoData,
  IPerfomanceInfoParams
} from '../interfaces/performance-info-interfaces';
import { IReportsData, IReportsParams } from '../interfaces/reports-interfaces';

/**
 * Common util to generate url query params
 * @param params - query params object
 */
export const createQueryString = (
  params?: Record<string, string | number>
): string => {
  const searchParams = new URLSearchParams();

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.append(key, String(value));
    });
  }

  return searchParams.toString();
};

/**
 * Common fetcher util
 * @param endpoint - endpoint url
 * @param params - endpoint query params
 */
async function fetchData<T>(
  endpoint: string,
  params?: Record<string, string | number>,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
  requestBody?: BodyInit | null
): Promise<T> {
  try {
    const response = await fetch(`${endpoint}?${createQueryString(params)}`, {
      method,
      body: requestBody
    });
    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
}

export const fetchSavings = (params: ISavingsParams) => {
  return fetchData<ISavingsData>(CC_API_ENDPOINTS.savingsInfo, params);
};

export const fetchRunsSummary = (params: IRunsSummaryParams) => {
  return fetchData<IRunsSummaryData>(CC_API_ENDPOINTS.runsSummary, params);
};

export const fetchRunsInfo = (params: IRunsInfoParams) => {
  return fetchData<IRunsInfoData>(CC_API_ENDPOINTS.runsInfo, params);
};

export const fetchSprayerInfo = (params: ISprayerInfoParams) => {
  return fetchData<ISprayerInfoData>(CC_API_ENDPOINTS.sprayerInfo, params);
};

export const fetchSubLocationInfo = (params: ISubLocationInfoParams) => {
  return fetchData<ISubLocationInfoData>(
    CC_API_ENDPOINTS.subLocationInfo,
    params
  );
};

export const fetchBlocks = () => {
  return fetchData<string[]>(LOCATIONS_API_ENDPOINTS.blocks);
};

export const fetchSubLocationSprayedInfo = (
  params: ISubLocationSprayedInfoParams
) => {
  return fetchData<ISubLocationSprayedInfoData>(
    CC_API_ENDPOINTS.subLocationSprayedInfo,
    params
  );
};

export const fetchPerfomanceInfo = (params: IPerfomanceInfoParams) => {
  return fetchData<IPerfomanceInfoData>(
    CC_API_ENDPOINTS.perfomanceInfo,
    params
  );
};

export const fetchReports = async (params: IReportsParams, blobs: FormData) => {
  const response = await fetchData<IReportsData>(
    REPORTS_API_ENDPOINTS.reports,
    params,
    'POST',
    blobs
  );
  return response;
};

export const fetchSavingsReports = () => {
  return fetchData(REPORTS_API_ENDPOINTS.savingsReport);
};
